import { breakpoints } from './variables';
import { breakpointUp } from './mq';

type breakpointsKeysType = keyof typeof breakpoints;
const breakpointsKeys = Object.keys(breakpoints) as breakpointsKeysType[];

export default `
	.d-none {
		display: none;
	}
	
	${breakpointsKeys
		.map(
			breakpoint => `
		${breakpointUp(breakpoint)} {
			.d-${breakpoint}-block {
				display: block;
			}
			.d-${breakpoint}-inline-block {
				display: inline;
			}
			.d-${breakpoint}-inline {
				display: inline;
			}
		}
	`
		)
		.join('')}
`;
