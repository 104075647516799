import React from 'react';
import { css } from '@emotion/core';
import { color } from '../styles/variables';
import LogoSvg from '../img/logo_claim_left.module.svg';
import { breakpointUp } from '../styles/mq';

const logoRatio = 518/64;
const logoHeight = 1.5;
const logoHeightMd = 2;
const logoHeightLg = 2.5;

const logoCss = (fill: string = color.text) => css`
	height: ${logoHeight}rem;
	width: ${logoHeight * logoRatio}rem;
	fill: ${fill};

	${breakpointUp('md')} {
		height: ${logoHeightMd}rem;
		width: ${logoHeightMd * logoRatio}rem;
		margin-top: 1.25rem;
	}

	${breakpointUp('lg')} {
		height: ${logoHeightLg}rem;
		width: ${logoHeightLg * logoRatio}rem;
		margin-top: 1.5rem;
	}
	
	path,
	rect {
		fill: ${fill};
	}
`;

type Props = {
	fill?: string;
};

const Logo: React.FC<Props> = ({ fill = color.text }) => {
	return (
		<>
			<LogoSvg css={logoCss(fill)} />
		</>
	);
};

export default Logo;
