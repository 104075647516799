import { color, fontFamily, fontSize, fontWeight, lineHeight, spacer } from './variables';

// Bootsrap's Reboot
export default `
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	
	html {
		font-family: sans-serif;
		line-height: 1.15;
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
		
	article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
		display: block;
	}
	
	body {
		margin: 0;
		font-family: ${fontFamily.base};
		font-size: ${fontSize.base};
		font-weight: ${fontWeight.normal};
		line-height: ${lineHeight.base};
		color: ${color.text};
		text-align: left;
		background-color: ${color.bg};
	}
		
	[tabindex="-1"]:focus:not(:focus-visible) {
		outline: 0 !important;
	}
	
	hr {
		box-sizing: content-box; // 1
		height: 0; // 1
		overflow: visible; // 2
	}
			
	h1, h2, h3, h4, h5, h6 {
		margin-top: 0;
		margin-bottom: ${spacer['5']};
	}

	p {
		margin-top: 0;
		margin-bottom: ${spacer['5']};
	}
		
	address {
		margin-bottom: ${spacer['5']};
		font-style: normal;
		line-height: inherit;
	}
	
	ol,
	ul,
	dl {
		margin-top: 0;
		margin-bottom: ${spacer['5']};
	}
	
	ol ol,
	ul ul,
	ol ul,
	ul ol {
		margin-bottom: 0;
	}
			
	blockquote {
		margin: 0 0 ${spacer['5']};
	}
	
	b,
	strong {
		font-weight: ${fontWeight.bold};
	}
	
	small {
		font-size: 80%;
	}
	
	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}
	
	sub { bottom: -.25em; }
	sup { top: -.5em; }
	
	a {
		color: ${color.link};
		text-decoration: none;
		background-color: transparent;
	}
	
	a:hover {
		color: ${color.linkHover};
		text-decoration: none;
	}
	
	a:not([href]),
	a:not([href]):hover {
		color: inherit;
		text-decoration: none;
	}
	
	img {
		vertical-align: middle;
		border-style: none;
	}
	
	svg {
		overflow: hidden;
		vertical-align: middle;
	}
		
	table {
		border-collapse: collapse;
	}
	
	th {
		text-align: inherit;
	}
	
	label {
		display: inline-block;
		margin-bottom: ${spacer['5']};
	}

	button {
		border-radius: 0;
	}
	
	button:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
	
	input,
	button,
	select,
	optgroup,
	textarea {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}
	
	button,
		input {
		overflow: visible;
	}
	
	button,
		select {
		text-transform: none;
	}
		
	[role="button"] {
		cursor: pointer;
	}
		
	select {
		word-wrap: normal;
	}
	
	
	
	button,
	[type="button"],
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button;
	}
	
	button:not(:disabled),
	[type="button"]:not(:disabled),
	[type="reset"]:not(:disabled),
	[type="submit"]:not(:disabled) {
		cursor: pointer;
	}
		
	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
	
	input[type="radio"],
	input[type="checkbox"] {
		box-sizing: border-box;
		padding: 0;
	}
		
	textarea {
		overflow: auto;
		resize: vertical;
	}
	
	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;
	}
	
	
	legend {
		display: block;
		width: 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: ${spacer['2']};
		font-size: ${fontSize.lg};
		line-height: inherit;
		color: inherit;
		white-space: normal;
	}
	
	progress {
		vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
	}
		
	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	
	[type="search"] {
		outline-offset: -2px; // 2. Correct the outline style in Safari.
		-webkit-appearance: none;
	}
	
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	
	
	::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
	}
		
	[hidden] {
		display: none !important;
	}
`;
