const BASE_SIZE = 16;

export const getRemSize = (size: number): string => `${size / BASE_SIZE}rem`;

export const resetButton = (display = 'inline-block', bg = 'transparent', border = '0'): string => `
	display: ${display};
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	background: ${bg};
	border: ${border};
	cursor: pointer;
	
	&:hover,
	&:active,
	&:focus {
		outline: 0;
	}
`;

export const singleLine = (): string => `
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const multiLine = (linesCount: number): string => `
	display: -webkit-box;
	-webkit-line-clamp: ${linesCount};
	-webkit-box-orient: vertical;
	overflow: hidden;
`;
