import { border, color, fontFamily, fontSize, fontWeight, lineHeight, spacer } from './variables';

export default `
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin-bottom: ${spacer['5']};
		font-family: ${fontFamily.heading};
		font-weight: ${fontWeight.bold};
		line-height: ${lineHeight.heading};
		color: ${color.text};
	}
	
	h1, .h1 { font-size: ${fontSize.h1}; }
	h2, .h2 { font-size: ${fontSize.h2}; }
	h3, .h3 { font-size: ${fontSize.h3}; }
	h4, .h4 { font-size: ${fontSize.h4}; }
	h5, .h5 { font-size: ${fontSize.h5}; }
	h6, .h6 { font-size: ${fontSize.h6}; }
	
	hr {
		margin-top: ${spacer['5']};
		margin-bottom: ${spacer['5']};
		border: 0;
		border-top: ${border.full};
	}
	
	small,
	.small {
		font-size: 75%
		font-weight: ${fontWeight.normal};
	}
`;
