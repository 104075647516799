import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
	title: string;
	description?: string;
	lang: string;
};

const Seo: React.FC<Props> = ({ title, description, lang }) => {
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | Kibala`}
			meta={[
				{
					name: `description`,
					content: description,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: description,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: description,
				},
			]}
		/>
	);
};

export default Seo;
