import { getRemSize } from './mixins';

export const fontSize = {
	base: getRemSize(16),
	sm: getRemSize(14),
	xs: getRemSize(12),
	md: getRemSize(18),
	lg: getRemSize(20),
	h1: getRemSize(40),
	h2: getRemSize(28),
	h3: getRemSize(24),
	h4: getRemSize(20),
	h5: getRemSize(18),
	h6: getRemSize(16),
};

export const spacer = {
	1: getRemSize(4),
	2: getRemSize(8),
	3: getRemSize(10),
	4: getRemSize(12),
	5: getRemSize(16),
	6: getRemSize(20),
	7: getRemSize(24),
	8: getRemSize(32),
	9: getRemSize(40),
	10: getRemSize(48),
	11: getRemSize(64),
	12: getRemSize(96),
	13: getRemSize(112),
	14: getRemSize(160),
	15: getRemSize(192),
};

export const gutter = {
	half: spacer['2'],
	full: spacer['5'],
	md: {
		half: spacer['5'],
		full: spacer['8'],
	},
};

export const fontFamily = {
	base: '"Poppins", sans-serif',
	heading: '"Poppins", sans-serif',
};

export const fontWeight = {
	normal: 400,
	bold: 700,
};

export const lineHeight = {
	base: 1.5,
	heading: 1.2,
	small: 1.2,
};

export const color = {
	text: '#000',
	textSemimuted: 'rgba(0, 0, 0, .75)',
	textMuted: 'rgba(0, 0, 0, .5)',
	bg: '#fff',
	link: '#000',
	linkHover: '#000',
	inverse: {
		text: '#fff',
		bg: '#000',
		link: '#fff',
		linkHover: '#fff',
	},
	gray: {
		0: '#050505',
		1: '#111',
		2: '#222',
		3: '#333',
		4: '#444',
		5: '#555',
		6: '#666',
		7: '#777',
		8: '#888',
		9: '#999',
		10: '#aaa',
		12: '#bbb',
		13: '#ccc',
		14: '#ddd',
		15: '#eee',
		16: '#f5f5f5',
	},
};

export const border = {
	width: '2px',
	color: '#000',
	colorMuted: 'rgba(0, 0, 0, .1)',
	full: '2px solid #000',
	fullMuted: '2px solid rgba(0, 0, 0, .075)',
	inverse: {
		color: '#fff',
	},
};

export const breakpoints = {
	//xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1440,
	xxxl: 1680,
};

export const header = {
	height: getRemSize(52),
	md: {
		height: getRemSize(80),
	},
};

export const zIndex = {
	nav: 1000,
};

const cubicBezier = 'cubic-bezier(.75, 0, .45, 1)';
export const transition = {
	cubicBezier: cubicBezier,
	hover: {
		duration: '.15s',
		timingFunction: 'ease-in-out',
	},
	toggleNav: {
		duration: '.15s',
		timingFunction: cubicBezier,
		togglerItemDelay: 100,
	},
};

export const slider = {
	ratio: '56.25%',
	sm: {
		ratio: '41.5%',
	},
};

export const card = {
	imgRatio: '56.25%',
};

export const map = {
	ratio: '66.66666666%',
};

export const opacity = {
	disabled: '.5',
};
